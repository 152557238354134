import { render, staticRenderFns } from "./tPacsDataList.vue?vue&type=template&id=51f26011&scoped=true&"
import script from "./tPacsDataList.vue?vue&type=script&lang=js&"
export * from "./tPacsDataList.vue?vue&type=script&lang=js&"
import style0 from "./tPacsDataList.vue?vue&type=style&index=0&id=51f26011&lang=less&"
import style1 from "./tPacsDataList.vue?vue&type=style&index=1&id=51f26011&scoped=less&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51f26011",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Java\\healthy\\healthy-frame-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('51f26011')) {
      api.createRecord('51f26011', component.options)
    } else {
      api.reload('51f26011', component.options)
    }
    module.hot.accept("./tPacsDataList.vue?vue&type=template&id=51f26011&scoped=true&", function () {
      api.rerender('51f26011', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/healthy/tPacsData/tPacsDataList.vue"
export default component.exports